table.fc-scroll {
  overflow: scroll;
  overflow-y: auto;
}
.ct_top {
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 20px #0027761c;
  border-radius: 8px;
  margin-bottom: 30px;
}
.ct_top section {
  display: flex;
  justify-content: space-between;
}
.CT_lab_wrap {
  /* display: flex; */
  /* justify-content: space-between; */
  margin-top: 30px;
  /* width: 100%; */
  /* margin-right: 30px; */
  flex-direction: column;
}
select {
  width: 200px;
  height: 35px;
  margin: 0;
  /* margin-top: 9px; */
  border-radius: 3px;
  padding: 0px 10px;
  border: 1px solid #d1d1d1;
  outline: none;
  background: white;
  margin-left: 20px;
}
.input {
  height: 35px;
  margin: 0;
  margin-top: 9px;
  border-radius: 3px;
  padding: 0px 10px;
  border: 1px solid #d1d1d1;
  outline: none;
  background: white;
}
.price_input {
  /* width: 100px; */
  text-align: right;
  margin: 0;
  width: 80px;
}
.ct_price {
  border: 0px;
  outline: none;
  background: transparent;
  padding: 0 !important;
}
.incoterms_input {
  width: 200px;
  margin-left: 14px;
}
.save_btn_cont {
  display: flex;
  flex-direction: column;
  gap: 7px;
  /* justify-content: flex-end; */
  margin-bottom: 20px;
}
.not_allow_cursor {
  opacity: 0.45;
  cursor: not-allowed;
}
.allow_cursor {
  cursor: pointer;
}
.save_button {
  display: flex;
  height: 40px;
  background: #002776;
  align-items: center;
  justify-content: center;
  width: 140px;
  border-radius: 4px;
  font-size: 14px;
  padding: 8px 25px;
  background-color: #002776;
  box-shadow: -1px 0px 7px 2px #0027764f;
  color: white;
  margin: 0 auto;
  transition: all ease 0.5s;
}
.save_button:hover {
  background: #4b64b5;
}
.save_button p {
  color: white;
}
.ct_weekbox_wrap {
  display: flex;
  gap: 10px;
}
.addNew {
  height: 32px;
  background: #002776;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 5px;
  position: relative;
  color: white;
  padding: 5px;
}
.cost_target_header_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.cost_target_header {
  display: flex;
  align-items: center;
}
.spacing {
  margin-bottom: 20px;
}
.week_data_align {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}
.week_data_align p {
  margin: 0;
}
.week_data_align p:nth-child(2) {
  margin: 0;
  margin-top: 10px;
}
.ct_la_wrap {
  display: flex;
  align-items: center;
  position: relative;
}
.ct_lab {
  font-size: 18px;
  color: #002776;
  font-family: "Poppins-Medium";
  background-color: #00ffff26;
  border-radius: 3px;
  min-height: 40px;
  padding: 0px 15px;
  /* line-height: 40px; */
  /* min-width: 100px; */
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
}
.ct_lab_out {
  position: relative;
}
.ct_lab_out p {
  color: #404855;
  font-size: 14px;
}
.priceListBlock div {
  padding: 10px;
}
.priceListBlock div.list_header_wrap {
  /* padding-left: 49px; */
}

.list_header_wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dot {
  height: 12px;
  width: 12px;
  background: #58637a;
  border-radius: 50%;
  /* margin-left: 8px; */
}
.circlewithno {
  height: 14px;
  width: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: #58637a;
  color: white;
  font-size: 8px;
  border-radius: 50%;
  cursor: pointer;
}
.ct_modal_text {
  font-size: 14.5px;
  font-family: "Poppins-Regular";
}
.ct_modal_text:nth-child(4) {
  margin-bottom: 25px;
}
.week_text_wrap {
  width: 228px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.week_text_wrap:last-child {
  width: 225px;
  margin-bottom: 20px;
}
.hide_arrow {
  opacity: 0;
}
.ct-history-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.ct-history-wrapper span {
  cursor: pointer;
  border: 1px solid #007681;
  padding: 5px 10px;
  color: #007681;
  border-radius: 10px;
}
.ct_week_flex {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
}

.ct_nok_price_holder {
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  width: 100px !important;
  justify-content: center;
  margin-left: auto;
  padding: 0px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
}

#delivery:focus {
  outline: none;
  border-color: #719ece;
  box-shadow: none;
}

@media only screen and (max-width: 1680px) {
  .ct_lab {
    font-size: 16px;
  }
  /* .mod_lab_out:nth-last-child(2) .mod_lab{
            font-size: 12px;
        } */
  .ct_lab_out p {
    font-size: 12px;
  }
}
