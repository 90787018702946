label {
  margin-right: 1rem;
}

.custom-select {
  /* wrapper style */
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
}
.custom-select select {
  /* Select Reset */
  border: 1px solid #b6b6b6;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  margin: 0;
  display: block;
  width: 100%;
  cursor: pointer;
  box-shadow: none;

  /* custom select appearance */
  font-size: 14px;
}

.custom-select:after {
  position: absolute;
  right: 0;
  top: 0;
}
